import React, { useState } from "react";
import "../styles/profile-card.scss";
import image from "../images/image.png";
import { GuiButton } from "./global-ui/gui-button";
import { appState } from "../redux/redux-config";
import api from "../api/api";
import { useOnFirstLoad } from "../utilities/hooks";
import { formatPhone, useUpdateState } from "../utilities/utilities";

export default function ProfileCard() {
  const [profileData, setProfileData] = useState(null); // Null initially to check loading
  const [loading, setLoading] = useState(true); // Loading state

  const [state, updateState] = useUpdateState({
    primaryEmail: "",
    notificationEmail: "",
    cellPhone: "",
    homePhone: "",
    workPhone: "",
    voice: "",
    address: "",
    name: "",
    role: "",
    orgName: "",
  });

  function onBtnClick() {
    console.log("Button clicked");
  }
  useOnFirstLoad(() => {
    const token = appState().user.token;

    // Fetch the user profile
    api.user.getUserProfile(token).then((result) => {
      if (result.success) {
        setProfileData(result);
        setLoading(false); // Mark loading as done

        updateState({ role: result.role });
        updateState({ orgName: result.customerName });
        // Extract name fo the user
        const name = result.firstName + " " + result.lastName;
        updateState({ name: name });
        // Extract primary email from contacts directly
        const contacts = result.contacts || [];
        const primaryEmailContact =
          contacts.find((contact) => contact.isPrimaryEmail === 1) || "";
        const primaryEmail =
          primaryEmailContact === "" ? "-" : primaryEmailContact.phone;
        updateState({
          primaryEmail: primaryEmail,
        });

        // Extract preference email and if we have empty preference email then show primary email
        const preferences = result.preferences || {};
        const notificationEmailContact =
          preferences.textNotificationEmail.smsNotifyEmail || "";

        updateState({
          notificationEmail:
            notificationEmailContact === ""
              ? primaryEmail
              : notificationEmailContact,
        });

        // Extract cell phone , work phone and home phone values.

        const cellPhone = getPhoneNumber(contacts, "Cell Phone");
        const workPhone = getPhoneNumber(contacts, "Work Phone");
        const homePhone = getPhoneNumber(contacts, "Home Phone");
        updateState({
          cellPhone: cellPhone,
          homePhone: homePhone,
          workPhone: workPhone,
        });

        // Extract voice values

        const voicePreferences = preferences.voice || {};
        const voice = voicePreferences
          ? voicePreferences.langDesc + " - " + voicePreferences.voice
          : "-";

        updateState({ voice: voice });
      }

      // Extract values for address
      const address =
        result.address +
        " " +
        result.city +
        " " +
        result.state +
        " " +
        result.zip;
      updateState({ address: address });
    });
  });
  const getPhoneNumber = (contacts, contactTypeName) => {
    const contact = contacts.find(
      (contact) => contact.contactTypeName === contactTypeName
    );
    const formattedPhone = contact
      ? formatPhone(contact.phone) === ""
        ? "-"
        : formatPhone(contact.phone)
      : "-";
    return formattedPhone;
  };

  // If loading, show a loading message or spinner
  // we will be adding loader in future sprints for this.
  if (loading) {
    return <div>Loading profile...</div>;
  }

  const {
    primaryEmail,
    notificationEmail,
    cellPhone,
    homePhone,
    workPhone,
    voice,
    address,
    name,
    role,
    orgName,
  } = state;

  return (
    <div className="profile-card">
      <div className="profile-header">
        <div className="background-curve"></div>
        <div className="profile-image-container">
          {/* add this when we have profile picture functionality */}
          {/* <img
            src={image} // Replace with actual image path
            alt="Profile"
            className="profile-image"
          /> */}
          <div className="initials">
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </div>
        </div>
        <div className="profile-info">
          <div className="profile-name">
            <h4>{name}</h4>
            <div className="edit-icon">
              <i className="fa fa-pencil"></i>
            </div>
          </div>
          {/* need to get organization name */}
          <p className="organization">{orgName}</p>
        </div>
      </div>

      <div className="profile-card-body">
        <div className="permissions">
          <p className="section-title">Permissions</p>
          <div className="section-body">
            <div>
              <i className="fa fa-key"></i>
              <span className="section-body-title">My Role</span>
            </div>
            <GuiButton className="role-badge" onClick={onBtnClick}>
              {role}
            </GuiButton>
          </div>
        </div>

        <div className="primary-contact">
          <p className="section-title">Primary Contact</p>
          <div className="section-body">
            <div>
              <i className="fa fa-envelope"></i>
              <span className="section-body-title">Email</span>
            </div>
            <span className="section-body-title">{primaryEmail}</span>
          </div>
        </div>
        <div className="profile-horizontal-divider"></div>
        <div className="contact-methods">
          <div className="section-title">
            <div className="section-title-header">
              <div>Contact Methods</div>
              <div className="add-edit-btns">
                <GuiButton className="add-edit-contact" onClick={onBtnClick}>
                  <i className="fa fa-add"></i> Contact
                </GuiButton>
                <GuiButton className="add-edit-contact" onClick={onBtnClick}>
                  <i className="fa fa-pencil"></i> Edit
                </GuiButton>
              </div>
            </div>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-envelope"></i>
              <span className="section-body-title">Email</span>
            </div>
            <span className="section-body-title">{primaryEmail}</span>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-mobile-notch"></i>
              <span className="section-body-title">Cell Phone</span>
            </div>
            <span className="section-body-title">{cellPhone}</span>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-phone"></i>
              <span className="section-body-title">Home Phone</span>
            </div>
            <span className="section-body-title">{homePhone}</span>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-phone-office"></i>
              <span className="section-body-title">Work Phone</span>
            </div>
            <span className="section-body-title">{workPhone}</span>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-map-marker-alt"></i>
              <span className="section-body-title">Address</span>
            </div>
            <span className="section-body-title">{address}</span>
          </div>
        </div>
        <div className="profile-horizontal-divider"></div>
        <div className="preferences">
          <div className="section-title">
            <div>Preferences</div>
            <GuiButton className="add-edit-contact" onClick={onBtnClick}>
              <i className="fa fa-pencil"></i>
              Edit
            </GuiButton>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-bell"></i>
              <span className="section-body-title">
                Text Notification Email
              </span>
            </div>
            <span className="section-body-title">{notificationEmail}</span>
          </div>

          <div className="section-body">
            <div>
              <i className="fa fa-volume-up"></i>
              <span className="section-body-title">Voice</span>
            </div>
            <span className="section-body-title">{voice}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
