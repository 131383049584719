import React from "react";
import ProfileCard from "../components/profile-card";
import PageLayout from "../components/page-layout";

export default function Dashboard() {
  return (
    <>
      <PageLayout title="My Dashboard"></PageLayout>
      <div style={{ padding: "24px" }}>
        <ProfileCard></ProfileCard>
      </div>
    </>
  );
}
