import { apiRequestWithChecks } from "./api";

export const user = {
  getUserInfo: function (token) {
    return apiRequestWithChecks("GET", "/api/user", {
      Authorization: "Bearer " + token,
    });
  },

  getUserCount: function (token) {
    return apiRequestWithChecks("GET", "/api/user/count", {
      Authorization: "Bearer " + token,
    });
  },

  getUserTypes: function (token) {
    return apiRequestWithChecks("GET", "/api/user/types", {
      Authorization: "Bearer " + token,
    });
  },

  getUserProfile: function (token) {
    return apiRequestWithChecks("GET", "api/user/GetUserProfile", {
      Authorization: "Bearer " + token,
    });
  },
};
